import { createRef, useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import compareVersions from "compare-versions";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { TextareaAutosize } from "@mui/material";
import * as Yup from "yup";
import { SRLWrapper } from "simple-react-lightbox";

import { ReactComponent as Ok } from "../../assets/image/svg/ok.svg";
import { ReactComponent as Vk } from "../../assets/image/svg/vk.svg";
import { Context } from "../../components/context/Context";
import "./InnerPage.scss";

const InnerPage = () => {
  const URL_WEB_APP = "https://cms-headless.odnodum.ru";
  const context = useContext(Context);
  const { id } = useParams();
  const [nid, setNid] = useState();
  const [body, setBody] = useState();
  const [comments, setComments] = useState();
  const commentFormikRef = createRef();
  const PITER_ID_VK = "123855316";
  const PITER_ID_OK = "521849337412";

  const signupSchema = Yup.object().shape({
    title: Yup.string()
      .min(2, "Слишком короткий заголовок")
      .max(25, "Текст заголовка не может быть более 25 символов")
      .required("Введите заголовок комментария"),
    text: Yup.string()
      .min(2, "Слишком короткий текст комментария")
      .max(200, "Текст комментария не может больше 200 символов")
      .required("Введите текст комментария"),
  });

  const initialValues = {
    title: "",
    text: "",
  };

  const onSubmit = async (values, grecaptcha, props) => {
    switch (props.action) {
      case "reply":
        await replyComment(values, props.cid);
        break;
      case "edit":
        await editComment(values, props.cid);
        break;
      default:
        await postComment(values);
        break;
    }
    await grecaptcha.resetForm();
    await getComments();
  };

  const renderError = (message) => <small className="error">{message}</small>;

  const clickBtnComment = (e) => {
    e.target.nextElementSibling.classList.toggle("active");
  };

  const FormikComment = (props) => (
    <Formik
      innerRef={commentFormikRef}
      initialValues={initialValues}
      validationSchema={signupSchema}
      onSubmit={async (values, grecaptcha) => {
        await onSubmit(values, grecaptcha, props);
      }}
    >
      <Form className="form">
        <Field className="field" name="title" placeholder="Введите заголовок" />
        <ErrorMessage name="title" render={renderError} />
        <Field name="text">
          {({ field }) => (
            <>
              <TextareaAutosize
                className="field"
                type="text"
                placeholder="Введите текст комментария"
                minRows={5}
                {...field}
              />
            </>
          )}
        </Field>
        <ErrorMessage name="text" render={renderError} />
        <button className="btn-outlined" type="submit">
          Сохранить
        </button>
      </Form>
    </Formik>
  );

  const getData = async () => {
    try {
      const response = await fetch(
        `${URL_WEB_APP}/rest/api/article_id?_format=hal_json&uuid=${id}`
      );
      const json = await response.json();
      setNid(json[0].nid);
      setBody(parse(json[0].body));
      // console.log("json", json);
      // console.log("Body", parse(json[0].body));
    } catch (error) {
      console.log(error);
    }
  };

  const getUserInfo = async (id) => {
    try {
      const response = await fetch(
        `${URL_WEB_APP}/user/api/getUser?id=${id}&_format=json`
      );
      const json = await response.json();
      return json;
    } catch (error) {
      console.log(error);
    }
  };

  const countDots = (str) => {
    const re = /[.]/g;
    return ((str || "").match(re) || []).length;
  };

  const getComments = async () => {
    try {
      const response = await fetch(
        `${URL_WEB_APP}/rest/api/comments?uuid=${id}&_format=json`
      );
      const json = await response.json();
      if (json?.length !== 0) {
        const data_1 = await Promise.all(
          json.map(async (el) => {
            const response = await getUserInfo(el.uid);
            const url = response.url;
            const name = response.name;
            return { ...el, url, name };
          })
        );

        const data_2 = data_1.map((el) => {
          let thread = el.thread.split("/").join("");
          return { ...el, thread };
        });

        const dataSorted = data_2.sort((a, b) => {
          return compareVersions(a.thread, b.thread);
        });
        setComments(dataSorted);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const postComment = async (values) => {
    try {
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/hal+json");
      myHeaders.append("X-CSRF-Token", context.tokenCSRF);
      myHeaders.append("Authorization", `Bearer ${context.accessToken}`);
      myHeaders.append("Accept", "application/hal+json");

      const raw = JSON.stringify({
        _links: {
          type: {
            href: "https://cms-headless.odnodum.ru/rest/type/comment/comment",
          },
          "https://cms-headless.odnodum.ru/relation/comment/comment/entity_id":
            {
              href:
                "cms-headless.odnodum.ru/rest/type/comment/comment/node/" + nid,
            },
        },
        entity_id: [
          {
            target_id: nid,
          },
        ],
        subject: [
          {
            value: values.title,
          },
        ],
        entity_type: [
          {
            value: "node",
          },
        ],
        comment_type: [
          {
            target_id: "comment",
          },
        ],
        field_name: [
          {
            value: "comment",
          },
        ],
        comment_body: [
          {
            value: values.text,
          },
        ],
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      await fetch(`${URL_WEB_APP}/comment?_format=hal_json`, requestOptions);
    } catch (error) {
      console.log(error);
    }
  };

  const editComment = async (values, cid) => {
    try {
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/hal+json");
      myHeaders.append("X-CSRF-Token", context.tokenCSRF);
      myHeaders.append("Authorization", `Bearer ${context.accessToken}`);
      myHeaders.append("Accept", "application/hal+json");

      var raw = JSON.stringify({
        _links: {
          type: {
            href: "https://cms-headless.odnodum.ru/rest/type/comment/comment",
          },
        },
        subject: [
          {
            value: values.title,
          },
        ],
        comment_body: [
          {
            value: values.text,
          },
        ],
      });

      const requestOptions = {
        method: "PATCH",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      await fetch(
        `${URL_WEB_APP}/comment/${cid}?_format=hal_json`,
        requestOptions
      );
    } catch (error) {
      console.log(error);
    }
  };

  const replyComment = async (values, cid) => {
    try {
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("X-CSRF-Token", context.tokenCSRF);
      myHeaders.append("Authorization", `Bearer ${context.accessToken}`);
      myHeaders.append("Accept", "application/json");

      const raw = JSON.stringify({
        entity_id: [
          {
            target_id: nid,
          },
        ],
        pid: [
          {
            target_id: cid,
          },
        ],
        entity_type: [
          {
            value: "node",
          },
        ],
        comment_type: [
          {
            target_id: "comment",
          },
        ],
        field_name: [
          {
            value: "comment",
          },
        ],
        subject: [
          {
            value: values.title,
          },
        ],
        comment_body: [
          {
            value: values.text,
          },
        ],
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      await fetch(`${URL_WEB_APP}/comment/?_format=json`, requestOptions);
    } catch (error) {
      console.log(error);
    }
  };

  const cRe1 = /[.]/;

  const commentsRender =
    comments?.length > 0 &&
    comments.map((comment, index) => (
      <div className="comment-item-wrap" key={index}>
        {!cRe1.exec(comment.thread) && (
          <div className="comment-item">
            <div className="wrap-user-block">
              <div
                className="avatar"
                style={{
                  background: `url(${comment.url}) no-repeat top`,
                }}
              />
              <span className="user-name">{comment.name}</span>
              <span className="date-time">{comment.created}</span>
            </div>
            <div className="wrap-comment">
              <h6 className="subject">{comment.subject}</h6>
              <p className="comment-body">{comment.comment_body}</p>

              {context.userAuthenticated &&
                (context?.user?.isFriend === "true" ||
                  context?.user?.id === PITER_ID_VK ||
                  context?.user?.id === PITER_ID_OK) &&
                context.userUID !== comment.uid && (
                  <>
                    <div
                      onClick={clickBtnComment}
                      className="btn-outlined btn-comment"
                    >
                      Ответить
                    </div>
                    <div className="reply-comment-block mt-2">
                      <FormikComment action={"reply"} cid={comment.cid} />
                    </div>
                  </>
                )}
              {context.userAuthenticated &&
                (context?.user?.isFriend === "true" ||
                  context?.user?.id === PITER_ID_VK ||
                  context?.user?.id === PITER_ID_OK) &&
                context.userUID === comment.uid && (
                  <>
                    <div
                      onClick={clickBtnComment}
                      className="btn-outlined btn-comment"
                    >
                      Редактировать
                    </div>
                    <div className="edit-comment-block mt-2">
                      <FormikComment action={"edit"} cid={comment.cid} />
                    </div>
                  </>
                )}
            </div>
          </div>
        )}
        {!!cRe1.exec(comment.thread) && (
          <div
            style={{ marginLeft: countDots(comment.thread) * 28 }}
            className="comment-item"
          >
            <div className="wrap-user-block">
              <div
                className="avatar"
                style={{
                  background: `url(${comment.url}) no-repeat top`,
                }}
              />
              <span className="user-name">{comment.name}</span>
              <span className="date-time">{comment.created}</span>
            </div>
            <div className="wrap-comment">
              <h6 className="subject">{comment.subject}</h6>
              <p className="comment-body">{comment.comment_body}</p>
              {context.userAuthenticated &&
                (context?.user?.isFriend === "true" ||
                  context?.user?.id === PITER_ID_VK ||
                  context?.user?.id === PITER_ID_OK) &&
                context.userUID !== comment.uid && (
                  <>
                    <div
                      onClick={clickBtnComment}
                      className="btn-outlined btn-comment"
                    >
                      Ответить
                    </div>
                    <div className="reply-comment-block mt-2">
                      <FormikComment action={"reply"} cid={comment.cid} />
                    </div>
                  </>
                )}
              {context.userAuthenticated &&
                (context?.user?.isFriend === "true" ||
                  context?.user?.id === PITER_ID_VK ||
                  context?.user?.id === PITER_ID_OK) &&
                context.userUID === comment.uid && (
                  <>
                    <div
                      onClick={clickBtnComment}
                      className="btn-outlined btn-comment"
                    >
                      Редактировать
                    </div>
                    <div className="edit-comment-block mt-2">
                      <FormikComment action={"edit"} cid={comment.cid} />
                    </div>
                  </>
                )}
            </div>
          </div>
        )}
      </div>
    ));

  useEffect(() => {
    getData();
    getComments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="inner-page animate__animated animate__fadeIn">
      <Container>
        {body && (
          <SRLWrapper>
            <div className="wrap">
              {body}
              <Container className="wrap-comments">
                <h5 className="title-comments-block">Комментарии:</h5>
                <div className="message-group">
                  <div className="msg-block">
                    Оставлять комментарии могут только авторизованные на сайте
                    пользователи,
                    <br />
                    которые являются друзьями Петра Земляных в соцсетях.
                    Присоединяйтесь к друзьям!
                  </div>

                  <div className="btn-block">
                    <div className="btn border rounded p-2 px-3 me-2 d-md-inline-block">
                      <a
                        href={"https://ok.ru/profile/521849337412"}
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        <Ok className="soc-ok" />
                      </a>
                    </div>
                    <div className="btn border rounded p-2 px-3 d-md-inline-block">
                      <a
                        href={"https://vk.com/id123855316"}
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        <Vk className="soc-vk" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="comments-block">{commentsRender}</div>

                {context.userAuthenticated &&
                  (context?.user?.isFriend === "true" ||
                    context?.user?.id === PITER_ID_VK ||
                    context?.user?.id === PITER_ID_OK) && (
                    <div className="new-comment-block">
                      <h5 className="title-add-comment">
                        Добавить комментарий
                      </h5>
                      <FormikComment action={"post"} />
                    </div>
                  )}
              </Container>
            </div>
          </SRLWrapper>
        )}
      </Container>
    </div>
  );
};

export default InnerPage;
