import MenuList from "./menu-list/MenuList";

import "./Menu.scss";

const Menu = () => {
  const clickMenu = (event) => {
    if (event.target.closest(".menu-block")) {
      document.querySelector(".menu").classList.toggle("active");
      document.querySelector(".menu-list").classList.toggle("hidden");
      document.querySelector(".menu-block").classList.toggle("active");
    }
  };

  return (
    <div className="menu" onClick={clickMenu}>
      <div className="menu-block">
        <span className="line top"></span>
        <span className="line middle"></span>
        <span className="line bottom"></span>
      </div>
      <MenuList />
    </div>
  );
};

export default Menu;
