import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Collapse, TextareaAutosize, Alert, AlertTitle } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import * as Yup from "yup";

import ModalCustom from "../../../modal/ModalCustom";
import "./MenuList.scss";

const MenuList = () => {
  const URL_MAIL = "https://odnodum.ru";
  const [stateModalFeedback, setStateModalFeedback] = useState(false);
  const [progressFeedback, setProgressFeedback] = useState(false);
  const recaptchaRef = useRef();
  const formikRef = useRef();
  const KEY = "6Ldk-FEgAAAAAN4eoQOkXDHzWq_lgQjkB9-LfCUv";
  const [open, setOpen] = useState(false);

  const signupSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Минимальная длина 2 символа")
      .max(50, "Превышена максимальная длина 50 символов")
      .required("Введите Ваше имя"),
    email: Yup.string("Введите адрес эл.почты")
      .email("Введите действующий адрес эл.почты")
      .required("Требуется ввести адрес эл.почты"),
    text: Yup.string()
      .min(10, "Минимальная длина 10 символов")
      .max(500, "Превышена максимальная длина 500 символов")
      .required("Введите текст сообщения"),
    recaptcha: Yup.string().required("Пройдите тест ReCAPTCHA"),
  });

  const initialValues = {
    name: "",
    email: "",
    text: "",
    recaptcha: "",
  };

  const modalTitleFeedback = "Форма обратной связи";

  const onChangeReCAPTCHA = (value) => {
    formikRef.current.setFieldValue("recaptcha", value);
  };

  const renderError = (message) => {
    return <small className="error">{message}</small>;
  };

  const sendMessage = async (message) => {
    console.log("start sendMessage!");
    try {
      await fetch(
        `${URL_MAIL}/backend/api/mail.php?sendto=${message.email}&name=${message.name}&message=${message.text}`
      );
      console.log("finish sendMessage!");
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async (values, grecaptcha) => {
    setProgressFeedback(true);
    console.log("onSubmit!");
    console.log("values", values);
    setOpen(true);

    await sendMessage(values);

    recaptchaRef.current.reset();
    await grecaptcha.resetForm();
    setProgressFeedback(false);
    const interval = setInterval(() => {
      setOpen(false);
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  };

  const clickSubmenu = (e) => {
    e.target
      .closest(".nav-title")
      .querySelector(".cross")
      .classList.toggle("active");
    e.target.closest(".nav-title").nextSibling.classList.toggle("active");
  };

  const FormikFeedback = (
    <>
      <Collapse in={open}>
        <Alert
          severity="success"
          onClick={() => {
            setOpen(false);
          }}
        >
          <AlertTitle>Сообщение отправлено</AlertTitle>
        </Alert>
      </Collapse>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={signupSchema}
        onSubmit={async (values, grecaptcha) => {
          await onSubmit(values, grecaptcha);
        }}
      >
        <Form className="form">
          <Field className="field" name="name" placeholder="Введите Ваше имя" />
          <ErrorMessage name="name" render={renderError} />
          <Field
            className="field"
            name="email"
            placeholder="Введите Ваш адрес эл.почты"
          />
          <ErrorMessage name="email" render={renderError} />
          <Field name="text">
            {({ field }) => (
              <>
                <TextareaAutosize
                  className="field"
                  type="text"
                  placeholder="Введите текст сообщения"
                  minRows={5}
                  {...field}
                />
              </>
            )}
          </Field>
          <ErrorMessage name="text" render={renderError} />
          <ReCAPTCHA
            ref={recaptchaRef}
            className="recaptcha"
            name="recaptcha"
            sitekey={KEY}
            onChange={onChangeReCAPTCHA}
          />
          <ErrorMessage name="recaptcha" render={renderError} />
          <button className="btn-outlined mt-1" type="submit">
            Отправить
          </button>
        </Form>
      </Formik>
    </>
  );

  return (
    <>
      <nav className="menu-list hidden">
        <div className="nav-title" onClick={clickSubmenu}>
          <CloseIcon className="cross" />
          <span className="nav-title-text">Разделы сайта</span>
        </div>
        <ul className="menu-sublist">
          <Link to="/">
            <li className="menu-item first">Главная</li>
          </Link>
          <Link to="about">
            <li className="menu-item">О нашем фотоклубе</li>
          </Link>
          <Link to="contacts">
            <li className="menu-item">Контакты</li>
          </Link>
        </ul>
        <hr className="nav-line" />
        <div className="nav-title" onClick={clickSubmenu}>
          <CloseIcon className="cross" />
          <span className="nav-title-text">Сервисы</span>
        </div>
        <ul className="menu-sublist">
          <a
            href={"https://webmail.hosting.reg.ru"}
            target={"_blank"}
            rel="noreferrer"
          >
            <li className="menu-item first">Почта</li>
          </a>
          <div
            onClick={() => {
              setStateModalFeedback(true);
            }}
          >
            <li className="menu-item">Обратная связь</li>
          </div>
        </ul>
        <hr className="nav-line" />
      </nav>
      <ModalCustom
        isOpened={stateModalFeedback}
        onModalClose={() => {
          setStateModalFeedback(false);
        }}
        title={modalTitleFeedback}
        body={FormikFeedback}
        progress={progressFeedback}
      />
    </>
  );
};

export default MenuList;
