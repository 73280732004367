import { useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import EmailIcon from "@mui/icons-material/Email";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Collapse, TextareaAutosize, Alert, AlertTitle } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import { ReactComponent as Ok } from "../../assets/image/svg/ok.svg";
import { ReactComponent as Vk } from "../../assets/image/svg/vk.svg";

import ModalCustom from "../modal/ModalCustom";
import "./Footer.scss";

const Footer = () => {
  const URL_MAIL = "https://odnodum.ru";
  const [stateModalFeedback, setStateModalFeedback] = useState(false);
  const [progressFeedback, setProgressFeedback] = useState(false);
  const recaptchaRef = useRef();
  const formikRef = useRef();
  const KEY = "6Ldk-FEgAAAAAN4eoQOkXDHzWq_lgQjkB9-LfCUv";
  const [open, setOpen] = useState(false);

  const signupSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Минимальная длина 2 символа")
      .max(50, "Превышена максимальная длина 50 символов")
      .required("Введите Ваше имя"),
    email: Yup.string("Введите адрес эл.почты")
      .email("Введите действующий адрес эл.почты")
      .required("Требуется ввести адрес эл.почты"),
    text: Yup.string()
      .min(10, "Минимальная длина 10 символов")
      .max(500, "Превышена максимальная длина 500 символов")
      .required("Введите текст сообщения"),
    recaptcha: Yup.string().required("Пройдите тест ReCAPTCHA"),
  });

  const initialValues = {
    name: "",
    email: "",
    text: "",
    recaptcha: "",
  };

  const modalTitleFeedback = "Форма обратной связи";

  const onChangeReCAPTCHA = (value) => {
    formikRef.current.setFieldValue("recaptcha", value);
  };

  const renderError = (message) => {
    return <small className="error">{message}</small>;
  };

  const sendMessage = async (message) => {
    console.log("start sendMessage!");
    try {
      await fetch(
        `${URL_MAIL}/backend/api/mail.php?sendto=${message.email}&name=${message.name}&message=${message.text}`
      );
      console.log("finish sendMessage!");
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async (values, grecaptcha) => {
    setProgressFeedback(true);
    console.log("onSubmit!");
    console.log("values", values);
    setOpen(true);

    await sendMessage(values);

    recaptchaRef.current.reset();
    await grecaptcha.resetForm();
    setProgressFeedback(false);
    const interval = setInterval(() => {
      setOpen(false);
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  };

  const FormikFeedback = (
    <>
      <Collapse in={open}>
        <Alert
          severity="success"
          onClick={() => {
            setOpen(false);
          }}
        >
          <AlertTitle>Сообщение отправлено</AlertTitle>
        </Alert>
      </Collapse>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={signupSchema}
        onSubmit={async (values, grecaptcha) => {
          await onSubmit(values, grecaptcha);
        }}
      >
        <Form className="form">
          <Field className="field" name="name" placeholder="Введите Ваше имя" />
          <ErrorMessage name="name" render={renderError} />
          <Field
            className="field"
            name="email"
            placeholder="Введите Ваш адрес эл.почты"
          />
          <ErrorMessage name="email" render={renderError} />
          <Field name="text">
            {({ field }) => (
              <>
                <TextareaAutosize
                  className="field"
                  type="text"
                  placeholder="Введите текст сообщения"
                  minRows={5}
                  {...field}
                />
              </>
            )}
          </Field>
          <ErrorMessage name="text" render={renderError} />
          <ReCAPTCHA
            ref={recaptchaRef}
            className="recaptcha"
            name="recaptcha"
            sitekey={KEY}
            onChange={onChangeReCAPTCHA}
          />
          <ErrorMessage name="recaptcha" render={renderError} />
          <button className="btn-outlined mt-1" type="submit">
            Отправить
          </button>
        </Form>
      </Formik>
    </>
  );

  // const Mailto = ({ email, subject, body, ...props }) => {
  //   return (
  //     <a
  //       href={`mailto:${email}?subject=${
  //         encodeURIComponent(subject) || ""
  //       }&body=${encodeURIComponent(body) || ""}`}
  //     >
  //       {props.children}
  //     </a>
  //   );
  // };

  return (
    <div className="footer">
      <div className="footer-top-line" />
      <Container>
        <Row>
          <Col
            xs={6}
            md={6}
            lg={6}
            xl={6}
            className="button-block text-lg-end text-sm-center text-md-center align-items-center"
          >
            <div
              onClick={() => {
                setStateModalFeedback(true);
              }}
              className="post-btn border rounded p-2 px-3 ms-3 me-3 d-md-inline-block"
            >
              <span className="post-text">
                <span className="post-title">Обратная связь</span>
                <EmailIcon />
              </span>
            </div>
          </Col>
          <Col
            xs={6}
            md={6}
            lg={6}
            xl={6}
            className="social-block d-lg-flex text-sm-center text-md-center align-items-center"
          >
            <a
              href={"https://ok.ru/profile/521849337412"}
              target={"_blank"}
              rel="noreferrer"
            >
              <div className="post-btn border rounded p-2 px-3 me-3 d-md-inline-block">
                <Ok className="soc-ok" />
              </div>
            </a>
            <a
              href={"https://vk.com/id123855316"}
              target={"_blank"}
              rel="noreferrer"
            >
              <div className="post-btn border rounded p-2 px-3 me-3 d-md-inline-block">
                <Vk className="soc-vk" />
              </div>
            </a>
          </Col>
        </Row>
        <hr className="hr-line-footer" />
        <Row>
          <Col className="footer-menu-item text-end mb-4" md={4} lg={4} xl={4}>
            <Link to="/">Главная</Link>
          </Col>
          <Col className="footer-menu-item mb-4" md={4} lg={4} xl={4}>
            <Link to="about">О нашем фотоклубе</Link>
          </Col>
          <Col
            className="footer-menu-item text-start mb-4"
            md={4}
            lg={4}
            xl={4}
          >
            <Link to="contacts">Контакты</Link>
          </Col>
        </Row>
      </Container>
      <div className="footer-bottom-line text-center">
        <div className="wrap">
          {/* <span className="author">Development by Korobeynikov Egor, 2022</span> */}
          <span className="author">ОДНОДУМ.RU, 2022</span>
          {/* <span className="mail">
            <Mailto
              email="egorlo059@gmail.com"
              subject="Письмо от"
              body="Здравствуйте,"
            >
              egorlo059@gmail.com
            </Mailto>
          </span> */}
        </div>
      </div>
      <ModalCustom
        isOpened={stateModalFeedback}
        onModalClose={() => {
          setStateModalFeedback(false);
        }}
        title={modalTitleFeedback}
        body={FormikFeedback}
        progress={progressFeedback}
      />
    </div>
  );
};

export default Footer;
