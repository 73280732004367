import {
  AlternateEmail,
  NearMe,
  PhoneAndroid,
  Public,
} from "@mui/icons-material";
import { useState, useMemo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { YMaps, Map, Placemark } from "react-yandex-maps";

import "./Contacts.scss";

const Contacts = () => {
  const [zoom, setZoom] = useState(13);
  const mapState = useMemo(
    () => ({
      center: [56.112799, 69.517413],
      zoom,
      controls: ["zoomControl", "fullscreenControl"],
    }),
    [zoom]
  );

  const mapOption = {
    suppressMapOpenBlock: true,
    suppressObsoleteBrowserNotifier: true,
  };

  return (
    <div className="contacts-page animate__animated animate__fadeIn">
      <Container>
        <h1 className="title">Контактная информация</h1>
        <Row>
          <Col
            lg={6}
            className="block-map order-lg-first order-last animate__animated animate__fadeInLeft"
          >
            <div className="wrap-maps">
              <YMaps>
                <Map
                  modules={["control.ZoomControl", "control.FullscreenControl"]}
                  state={mapState}
                  options={mapOption}
                  width="100%"
                  height="100%"
                >
                  <Placemark
                    modules={[
                      "geoObject.addon.balloon",
                      "geoObject.addon.hint",
                    ]}
                    geometry={[56.112799, 69.517413]}
                    properties={{
                      hintContent: `Фотоклуб «Белая студия»`,
                      balloonContent: `Фотоклуб «Белая студия»`,
                    }}
                    options={{ openEmptyHint: true }}
                    onClick={() => setZoom((zoom) => (zoom === 13 ? 17 : 13))}
                  />
                </Map>
              </YMaps>
            </div>
          </Col>
          <Col
            className="block-contacts  animate__animated animate__fadeInRight"
            lg={6}
          >
            <p className="boss">Фотоклуб «Белая студия»</p>
            <p className="details">
              <NearMe className="icon" />
              <span>
                627755, Тюменская область, г.&nbsp;Ишим, улица Калинина&nbsp;7
              </span>
            </p>
            <p className="details">
              <PhoneAndroid className="icon" />
              <span>+7&nbsp;(902)&nbsp;815&nbsp;1253</span>
            </p>
            <p className="details">
              <Public className="icon" />
              <span>odnodum.ru</span>
            </p>
            <p className="details">
              <AlternateEmail className="icon" />
              <span>petr_zemlyanykh@odnodum.ru</span>
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Contacts;
