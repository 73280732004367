import LinearProgress from "@mui/material/LinearProgress";
import CloseIcon from "@mui/icons-material/Close";

import "./ModalCustom.scss";

const ModalCustom = (props) => {
  return (
    <div className={`modal-wrap ${props.isOpened ? "open" : "close"}`}>
      <div className="modal-container">
        <div onClick={props.onModalClose} className="modal-close">
          <CloseIcon className="icon-close" />
        </div>
        <h1 className="modal-title">{props.title}</h1>
        <div className="modal-body">{props.body}</div>
        <div
          className={props.progress ? "progress-line active" : "progress-line"}
        >
          <LinearProgress />
        </div>
      </div>
    </div>
  );
};

export default ModalCustom;
