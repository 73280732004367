// import { Parallax, Background } from "react-parallax";
import { Background } from "react-parallax";
import Container from "react-bootstrap/Container";
//import Image from "../../../assets/image/uncle-piter.jpg";
import Image from "../../../assets/image/church_view_text_min.jpg";
import "./TopBlockParallax.scss";

const TopBlockParallax = () => {
  return (
    <div className="top-block-parallax animate__animated animate__fadeIn">
      <Container>
        <Background className="custom-bg">
          <img className="responcive" src={Image} alt="background" />
        </Background>
        {/* <Parallax
          bgClassName="img-parallax"
          bgImageAlt="Piter Zemlyanih"
          strength={200}
        >
        
          <Background className="custom-bg">
            <img className="responcive" src={Image} alt="background" />
          </Background>
          <div className="block-parallax">
            <div className="wrap">
              <h1 className="title gradient-text animate__animated animate__fadeInRight animate__fast">
                Сайт фотографа Петра Земляных
              </h1>
              <h2 className="subtitle animate__animated animate__fadeInRightBig animate__fast">
                О том, что близко и дорого сердцу ...
              </h2>
              <h1 className="title animate__animated animate__fadeInRight animate__fast">
                Сайт об Ишиме и Приишимье, о&nbsp;людях, о&nbsp;любви, обо всем,
                что близко и дорого сердцу
              </h1>
              <h2 className="subtitle animate__animated animate__fadeInRightBig animate__fast">
                О том, что близко и дорого сердцу ...
              </h2>
            </div>
          </div>
        </Parallax> */}
      </Container>
    </div>
  );
};

export default TopBlockParallax;
