import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import Lightbox from "react-image-lightbox";
// import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import VisibilityIcon from "@mui/icons-material/Visibility";
import InfiniteScroll from "react-infinite-scroll-component";
import Masonry from "react-masonry-component";

import { Context } from "../../../components/context/Context";
import "./MainBlock.scss";

const MainBlock = () => {
  const context = useContext(Context);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const URL_WEB_APP = "https://cms-headless.odnodum.ru";

  // eslint-disable-next-line
  Array.prototype.flexFilter = function (criteria) {
    //set variables
    let matchFilters,
      matches = [],
      counter,
      current;

    //helper function to iterate over the criteria (filter criteria)
    matchFilters = (item) => {
      counter = 0;
      current = "";
      for (var n = 0; n < criteria.length; n++) {
        current = item[criteria[n]["Field"]] + ", ";
        // const regExpCurrent = new RegExp(current, "gi");
        // const regExp = new RegExp(criteria[n]["Values"], "gi");
        // if (criteria[n]["Values"].indexOf(item[criteria[n]["Field"]]) > -1) {
        //if (criteria[n]["Values"].includes(item[criteria[n]["Field"]])) {

        // console.log("item:", item);
        // console.log("criteria:", criteria);
        // console.log("current:", current);

        if (criteria[n]["Values"].indexOf(current) > -1) {
          // console.log("FOUND! current", current);
          // console.log('FOUND! criteria[n]["Values"]', criteria[n]["Values"]);
          counter++;
        }

        // if (regExp.test(current) || regExpCurrent.test(criteria[n]["Values"])) {
        //   console.log("FOUND! current", current);
        //   console.log('FOUND! criteria[n]["Values"]', criteria[n]["Values"]);
        //   console.log("matches:", matches);
        //   counter++;
        // }
      }
      // The array's current items satisfies all the filter criteria, if it is true
      return counter === +criteria.length;
    };

    for (var i = 0; i < this.length; i++) {
      if (matchFilters(this[i])) {
        matches.push(this[i]);
      }
    }
    return matches;
  };

  const getDataAll = async () => {
    try {
      const response = await fetch(
        `${URL_WEB_APP}/rest/api/article?_format=hal_json`
      );
      const json = await response.json();
      context.setImagesDataAll(json);
    } catch (ex) {
      console.log("parsing failed", ex);
    }
  };

  const getData = async () => {
    try {
      const response = await fetch(
        `${URL_WEB_APP}/rest/api/article?_format=hal_json&items_per_page=40&offset=${context.page}`
      );
      const json = await response.json();
      context.setImagesData([...context.imagesData, ...json]);
      context.setPage(context.page + 40);
    } catch (ex) {
      console.log("parsing failed", ex);
    }
  };

  const fetchData = () => {
    if (context.page <= context.imagesData.length) {
      context.setPage(context.page + 40);
      getData();
    }
  };

  const refresh = () => {
    console.log("refresh.....");
  };

  const masonryOptions = {
    transitionDuration: 700,
    itemSelector: ".photo-item",
  };

  const childElements = context.filteredImagesData.map((photo, index) => (
    <div
      className={
        "photo-item col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-4 col-xs-6 col-6 "
      }
      // className={
      //   "photo-item col-xxl-1 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-xs-6 col-6 "
      // }
      key={index}
    >
      <img
        className="img"
        src={URL_WEB_APP + photo.field_image}
        alt=""
        onClick={() => {
          setPhotoIndex(index);
          setIsOpen(true);
        }}
      />
      <Link className="link" to={"page/" + photo.uuid}>
        <VisibilityIcon className="icon-eye" />
      </Link>
    </div>
  ));

  const BtnLightbox = (props) => {
    return (
      <Link to={"page/" + props.id}>
        <button className="btn-light-box">Открыть</button>
      </Link>
    );
  };

  useEffect(() => {
    if (context.imagesData.length === 0) {
      getData();
      getDataAll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (context.imagesData.length !== 0) {
      if (context.filter[0]?.Values.length === 0) {
        context.setFilteredImagesData(context.imagesData);
      } else {
        context.setFilteredImagesData(
          context.imagesData.flexFilter(context.filter)
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.imagesData]);

  useEffect(() => {
    if (context.filter[0]?.Values === "") {
      context.setFilteredImagesData(context.imagesData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.filter]);

  return (
    <div className="main-block">
      <InfiniteScroll
        dataLength={context.filteredImagesData.length}
        next={fetchData}
        hasMore={false}
        loader={<h4>Загрузка...</h4>}
        // endMessage={
        //   <p style={{ textAlign: "center" }}>
        //     <b>Все изображения загружены</b>
        //   </p>
        // }
        refreshFunction={refresh}
        pullDownToRefresh
        pullDownToRefreshThreshold={5}
        pullDownToRefreshContent={
          <h3 style={{ textAlign: "center" }}>&#8595; Pull down to refresh</h3>
        }
        releaseToRefreshContent={
          <h3 style={{ textAlign: "center" }}>&#8593; Release to refresh</h3>
        }
      >
        {/* <Container className="container-masonry" fluid> */}
        <Container className="container-masonry">
          <Masonry
            className={"photo-list"}
            elementType={"div"}
            options={masonryOptions}
            disableImagesLoaded={false}
            updateOnEachImageLoad={false}
            enableResizableChildren={true}
          >
            {childElements}
          </Masonry>
          {context.page <= context.filteredImagesData.length &&
            context.filteredImagesData.length > 0 && (
              <button className="btn-outlined btn-custom" onClick={fetchData}>
                Показать больше
              </button>
            )}
        </Container>
      </InfiniteScroll>

      {isOpen && (
        <Lightbox
          mainSrc={
            URL_WEB_APP + context.filteredImagesData[photoIndex].field_image
          }
          imageCaption={context.filteredImagesData[photoIndex].field_annotation}
          nextSrc={
            +photoIndex === context.filteredImagesData.length - 1
              ? undefined
              : URL_WEB_APP +
                context.filteredImagesData[
                  (photoIndex + 1) % context.filteredImagesData.length
                ].field_image
          }
          prevSrc={
            +photoIndex === 0
              ? undefined
              : URL_WEB_APP +
                context.filteredImagesData[
                  (photoIndex - 1) % context.filteredImagesData.length
                ].field_image
          }
          onCloseRequest={() => {
            setIsOpen(false);
          }}
          onMoveNextRequest={() => {
            setPhotoIndex((photoIndex + 1) % context.filteredImagesData.length);
          }}
          onMovePrevRequest={() => {
            setPhotoIndex((photoIndex - 1) % context.filteredImagesData.length);
          }}
          toolbarButtons={[
            <BtnLightbox id={context.filteredImagesData[photoIndex].uuid} />,
          ]}
        />
      )}
    </div>
  );
};

export default MainBlock;
