import { createContext, useState } from "react";

export const Context = createContext([]);

const ContextProvider = (props) => {
  const [user, setUser] = useState({});
  const [userAuthenticated, setUserAuthenticated] = useState(false);
  const [userUID, setUserUID] = useState("");
  const [tokenCSRF, setTokenCSRF] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [refreshToken, setRefreshToken] = useState("");
  const [imagesData, setImagesData] = useState([]);
  const [imagesDataAll, setImagesDataAll] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [filteredImagesData, setFilteredImagesData] = useState([]);
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState([]);

  return (
    <Context.Provider
      value={{
        user,
        setUser,
        userAuthenticated,
        setUserAuthenticated,
        userUID,
        setUserUID,
        tokenCSRF,
        setTokenCSRF,
        accessToken,
        setAccessToken,
        refreshToken,
        setRefreshToken,
        imagesData,
        setImagesData,
        imagesDataAll,
        setImagesDataAll,
        categoryList,
        setCategoryList,
        tagList,
        setTagList,
        filteredImagesData,
        setFilteredImagesData,
        page,
        setPage,
        filter,
        setFilter,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default ContextProvider;
