import Image from "../../assets/image/Error404.jpg";
import "./Page404.scss";

const Page404 = () => {
  return (
    <div className="page-404 animate__animated animate__fadeIn">
      <img className="responcive img-404" src={Image} alt="page 404" />
    </div>
  );
};
export default Page404;
