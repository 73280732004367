import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SimpleReactLightbox from "simple-react-lightbox";

import ContextProvider from "./components/context/Context";
import Home from "./pages/home-page/HomePage";
import AboutUs from "./pages/about-us-page/AboutUsPage";
import Contacts from "./pages/contacts/Contacts";
import PageInner from "./pages/inner-page/InnerPage";
import Page404 from "./pages/404-page/Page404";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import WebStub from "./pages/web-stub/WebStub";
import "./assets/styles/main.scss";
import "animate.css/animate.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-image-lightbox/style.css";

export const Context = React.createContext();

const App = () => {
  return (
    <Router>
      <ContextProvider>
        <div className="main-page">
          <Header />
          <SimpleReactLightbox>
            <main className="main">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<AboutUs />} />
                <Route path="/contacts" element={<Contacts />} />
                <Route path="/page/:id" element={<PageInner />} />
                <Route path="/oauth-callback" element={<WebStub />} />
                <Route path="*" element={<Page404 />} />
              </Routes>
            </main>
          </SimpleReactLightbox>
          <Footer />
        </div>
      </ContextProvider>
    </Router>
  );
};

export default App;
