import { useContext, useEffect, useState } from "react";
import Carousel from "react-multi-carousel";

import { Context } from "../../context/Context";
import "react-multi-carousel/lib/styles.css";
import "./Search.scss";

const Search = () => {
  const context = useContext(Context);
  const [searchTerm, setSearchTerm] = useState("");

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 7,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 7,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 6,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 4,
    },
  };

  const clickCategory = (e) => {
    e.target.classList.toggle("active");
    if (e.target.classList.contains("active")) {
      // console.log("clickCategory active");
      // console.log(context.filter);

      if (
        context.filter[0]?.Values.length !== 0 &&
        context.filter[0]?.Field === "field_category"
      ) {
        // console.log("yes");
        // console.log("context.filter[0].Values:", context.filter[0].Values);
        context.setFilter([
          {
            Field: "field_category",
            Values: context.filter[0].Values + [e.target.textContent] + ", ",
          },
        ]);
        // console.log("context.filter", context.filter);
      } else {
        // console.log("no");
        document.querySelectorAll(".tag-item").forEach((el) => {
          el.classList.remove("active");
        });
        context.setFilter([
          {
            Field: "field_category",
            Values: [e.target.textContent] + ", ",
          },
        ]);
      }
    } else {
      // console.log("clickCategory no active");
      context.setFilter([
        {
          Field: "field_category",
          Values: context.filter[0].Values.replace(
            [e.target.textContent] + ", ",
            ""
          ),
        },
      ]);
    }
  };

  const clickTags = (e) => {
    e.target.classList.toggle("active");
    if (e.target.classList.contains("active")) {
      // console.log("clickTags active");
      // console.log("context.filter", context.filter);
      if (
        context.filter[0]?.Values.length !== 0 &&
        context.filter[0]?.Field === "field_tags"
      ) {
        // console.log("yes");
        // console.log("context.filter[0].Values:", context.filter[0].Values);
        context.setFilter([
          {
            Field: "field_tags",
            Values: context.filter[0].Values + [e.target.textContent] + ", ",
          },
        ]);
        // console.log("context.filter", context.filter);
      } else {
        // console.log("no");
        document.querySelectorAll(".category").forEach((el) => {
          el.classList.remove("active");
        });
        context.setFilter([
          {
            Field: "field_tags",
            Values: [e.target.textContent] + ", ",
          },
        ]);
      }
    } else {
      // console.log("clickTags no active");
      context.setFilter([
        {
          Field: "field_tags",
          Values: context.filter[0].Values.replace(
            [e.target.textContent] + ", ",
            ""
          ),
        },
      ]);
    }
  };

  const clickSearch = () => {
    document.querySelectorAll(".category").forEach((el) => {
      el.classList.remove("active");
    });
    document.querySelectorAll(".tag-item").forEach((el) => {
      el.classList.remove("active");
    });
    context.setFilteredImagesData(context.imagesData);
  };

  const categoryList = context.categoryList.map((category, index) => (
    <div key={index}>
      <span className="category" onClick={clickCategory}>
        {category.name}
      </span>
    </div>
  ));

  const tagList = context.tagList.map((tag, index) => (
    <div key={index} className="tag-item" onClick={clickTags}>
      {tag.name}
    </div>
  ));

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const getCategoryList = async () => {
    try {
      const response = await fetch(
        "https://cms-headless.odnodum.ru/rest/api/categories?_format=hal_json"
      );
      const json = await response.json();
      return json;
    } catch (ex) {
      console.log("parsing failed", ex);
    }
  };

  const getTagList = async () => {
    try {
      const response = await fetch(
        "https://cms-headless.odnodum.ru/rest/api/tags?_format=hal_json"
      );
      const json = await response.json();
      return json;
    } catch (ex) {
      console.log("parsing failed", ex);
    }
  };

  const filterItems = (query) => {
    // console.log(query);
    //return context.imagesData.filter((el) => {
    return context.imagesDataAll.filter((el) => {
      // console.log(el.title);
      return el.title.toLowerCase().indexOf(query.toLowerCase()) > -1;
    });
  };

  useEffect(() => {
    if (context.imagesData.length !== 0) {
      // console.log("context.filter", context.filter);
      context.setFilteredImagesData(
        //context.imagesData.flexFilter(context.filter)
        context.imagesDataAll.flexFilter(context.filter)
      );
    }
    if (context.filter[0]?.Values.length === 0) {
      context.setFilteredImagesData(context.imagesData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.filter]);

  useEffect(() => {
    getCategoryList().then((res) => {
      context.setCategoryList(res);
    });
    getTagList().then((res) => {
      context.setTagList(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (context.imagesData.length !== 0) {
      // console.log(searchTerm);
      // filterItems(searchTerm);
      context.setFilteredImagesData(filterItems(searchTerm));
    }
    if (searchTerm === "") {
      // console.log("searchTerm empty!", searchTerm);
      context.setFilteredImagesData(context.imagesData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  return (
    <div className="search">
      <div className="search-block">
        <input
          className="search-input"
          id="search"
          name="search"
          onClick={clickSearch}
          value={searchTerm}
          onChange={handleChange}
          placeholder="Поиск материалов"
          type="search"
        />
        <div className="category-title">Категории:</div>
        <div className="category-block">{categoryList}</div>
        <span className="tag-title">Теги:</span>
        <Carousel
          responsive={responsive}
          showDots={false}
          removeArrowOnDeviceType={["mobile"]}
          infinite={true}
          autoPlay={true}
          containerClass="carousel-container"
        >
          {tagList}
        </Carousel>
      </div>
    </div>
  );
};

export default Search;
