import { useContext } from "react";
import { Context } from "../../context/Context";
import "./Logout.scss";

const Logout = (props) => {
  const context = useContext(Context);

  const logout = () => {
    document.querySelector(".logout").classList.remove("active");
    context.setUserAuthenticated(false);
    context.setAccessToken("");
    context.setRefreshToken("");
    context.setUser({});
  };

  return (
    <div className="logout">
      <img className="avatar" src={props.user.photo} alt="avatar" />
      <h4 className="user-name">
        {props.user.firstName} {props.user.lastNAme}
      </h4>
      <button onClick={logout} className="btn-outlined-rectangle">
        Выйти
      </button>
    </div>
  );
};

export default Logout;
