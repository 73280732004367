import { useContext, useState } from "react";

import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import SearchIcon from "@mui/icons-material/Search";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import fetchJsonp from "fetch-jsonp";
import Login from "react-simple-oauth2-login";
import sha256 from "crypto-js/sha256";
import hmacSHA512 from "crypto-js/hmac-sha512";
import Base64 from "crypto-js/enc-base64";
import { slugify } from "transliteration";

import { Context } from "../context/Context";
import { ReactComponent as Logo } from "../../assets/image/svg/logo192-p.svg";
import { ReactComponent as Ok } from "../../assets/image/svg/ok.svg";
import { ReactComponent as Vk } from "../../assets/image/svg/vk.svg";
import Menu from "./menu/Menu";
import Search from "./search/Search";
import Logout from "./logout/Logout";
import ModalCustom from "../modal/ModalCustom";
import "./Header.scss";

const Header = () => {
  const context = useContext(Context);
  const [stateModal, setStateModal] = useState(false);
  const [progress, setProgress] = useState(false);

  const CLIENT_ID_VK = "8146474"; // мой
  // const CLIENT_ID_VK = "8149181"; // Петр
  const CLIENT_ID_OK = "512001003169"; // Мой
  // const CLIENT_ID_OK = "512001357628"; // Петр
  const APP_KEY_OK = "CCKPEEKGDIHBABABA"; // Мой
  // const APP_KEY_OK = "CNDILJKGDIHBABABA"; // Петр
  //const REDIRECT_URI = "http://localhost:3000/oauth-callback";
  const REDIRECT_URI = "https://odnodum.ru/oauth-callback";
  const URL_WEB_APP = "https://cms-headless.odnodum.ru";

  let md5 = require("md5");

  const search = () => {
    document.querySelector(".search").classList.toggle("active");
  };

  const getUserUID = async (accessToken) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + accessToken);
    try {
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      const response = await fetch(
        URL_WEB_APP + "/oauth/debug?_format=json",
        requestOptions
      );
      const json = await response.json();
      context.setUserUID(json.id);
    } catch (ex) {
      console.log("parsing failed", ex);
    }
  };

  const getUserInfo = async (provider, parametrs, sig) => {
    switch (provider) {
      case "vk": {
        try {
          const response = await fetchJsonp(
            `https://api.vk.com/method/users.get?user_id=${parametrs.user_id}&v=5.131&fields=photo_max_orig&access_token=${parametrs.access_token}`
          );
          const json = await response.json();
          return await json.response[0];
        } catch (ex) {
          console.log("parsing failed", ex);
        }
        break;
      }
      case "ok": {
        try {
          const response = await fetch(
            `https://api.ok.ru/fb.do?application_key=${APP_KEY_OK}&format=json&method=users.getCurrentUser&sig=${sig}&access_token=${parametrs.access_token}`
          );
          const json = await response.json();
          return await json;
        } catch (ex) {
          console.log("parsing failed", ex);
        }
        break;
      }
      default:
        break;
    }
  };

  const checkFriend = async (id) => {
    try {
      const response = await fetch(
        `${URL_WEB_APP}/social/api/getSocial?id=${id}`
      );
      const json = response.text();
      return await json;
    } catch (err) {
      console.log("Error:", err);
    }
  };

  const onSuccessVK = async (response) => {
    let user = {};
    let friendState = false;
    await getUserInfo("vk", response).then((data) => {
      user = data;
    });
    await checkFriend(user.id).then((res) => {
      friendState = res;
    });
    context.setUser({
      provider: "vk",
      accessToken: response.access_token,
      id: user.id,
      firstName: user.first_name,
      lastNAme: user.last_name,
      nickName: user.nickname,
      photo: user.photo_max_orig,
      status: "connected",
      isFriend: friendState,
    });

    await loginWebApp("vk", user);

    setProgress(false);
    setStateModal(false);
  };

  const onFailureVK = (response) => {
    setProgress(false);
    console.log(response);
  };

  const onSuccessOK = async (response) => {
    const sig = md5(
      `application_key=${APP_KEY_OK}format=jsonmethod=users.getCurrentUser${response.session_secret_key}`
    );
    let user = {};
    let friendState = false;
    await getUserInfo("ok", response, sig).then((data) => {
      user = data;
    });
    await checkFriend(user.uid).then((res) => {
      friendState = res;
    });
    context.setUser({
      provider: "ok",
      accessToken: response.access_token,
      id: user.uid,
      firstName: user.first_name,
      lastNAme: user.last_name,
      nickName: user.name,
      photo: user.pic_3,
      status: "connected",
      isFriend: friendState,
    });

    await loginWebApp("ok", user);

    setProgress(false);
    setStateModal(false);
  };

  const onFailureOK = (response) => {
    setProgress(false);
    console.error("Error:", response);
  };

  const loginWebApp = async (provider, user) => {
    const clientId = "a15111e1-8b4e-45c5-a2b3-d0638945cda2";
    const clientSecret = "ssm6H?2@ZO5VMpnlJ5Y4";
    let userId = ""; // id user (provider social network)
    let uid = ""; // id user (web app)
    let fid = ""; // id picture (web app). Used for attach user picture.
    let userFullName = "";
    let ImgfileName = "";
    let tokenCSRF = "";
    let accessToken = "";
    let encryptedPassword = "";
    let imageFile = "";

    const getTokenCSRF = async () => {
      try {
        const response = await fetch(`${URL_WEB_APP}/session/token`);
        const json = await response.text();
        tokenCSRF = json;
        context.setTokenCSRF(tokenCSRF);
        return json;
      } catch (ex) {
        console.log("parsing failed", ex);
      }
    };

    const regUser = async () => {
      try {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/hal+json");
        myHeaders.append("X-CSRF-Token", tokenCSRF);

        const raw = JSON.stringify({
          _links: {
            type: {
              href: URL_WEB_APP + "/rest/type/user/user",
            },
          },
          name: [
            {
              value: userFullName,
            },
          ],
          mail: [
            {
              value: userId + "@odnodum.ru",
            },
          ],
          pass: [
            {
              value: encryptedPassword,
            },
          ],
        });

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        const response = await fetch(
          URL_WEB_APP + "/user/register?_format=hal_json",
          requestOptions
        );
        const result = await response.text();
        return JSON.parse(result);
      } catch (ex) {
        console.log("parsing failed", ex);
      }
    };

    const oauthWebApp = async (userFullName, id) => {
      const privateKey = "cjajrk";
      const hashDigest = sha256(id);
      encryptedPassword = Base64.stringify(hmacSHA512(hashDigest, privateKey));
      try {
        const formdata = new FormData();
        formdata.append("grant_type", "password");
        formdata.append("client_id", clientId);
        formdata.append("client_secret", clientSecret);
        formdata.append("username", userFullName);
        formdata.append("password", encryptedPassword);
        const requestOptions = {
          method: "POST",
          body: formdata,
          redirect: "follow",
        };
        const response = await fetch(
          `${URL_WEB_APP}/oauth/token`,
          requestOptions
        );
        const result = await response.text();
        context.setUserAuthenticated(true);
        const res = await JSON.parse(result);
        context.setAccessToken(res.access_token);
        context.setRefreshToken(res.refresh_token);
        getUserUID(res.access_token);
        return res;
      } catch (ex) {
        console.log("parsing failed", ex);
      }
    };

    const getImageFile = async (url) => {
      let blob = await fetch(url).then((r) => r.blob());
      const reader = new FileReader();
      const file = blob;
      if (file) {
        imageFile = file;
        reader.onloadend = () => {
          imageFile = reader.result;
        };
        reader.readAsDataURL(file);
      }
    };

    const uploadFile = async (imageFile, ImgfileName) => {
      try {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${accessToken}`);
        myHeaders.append("X-CSRF-Token", tokenCSRF);
        myHeaders.append("Content-Type", "application/octet-stream");
        myHeaders.append(
          "Content-Disposition",
          `file; filename="${ImgfileName}.jpg"`
        );
        const file = imageFile;
        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: file,
          redirect: "follow",
        };
        const response = await fetch(
          URL_WEB_APP +
            "/file/upload/media/image/field_media_image?_format=hal_json",
          requestOptions
        );
        const result = await response.text();
        return JSON.parse(result);
      } catch (ex) {
        console.log("parsing failed", ex);
      }
    };

    const pathUser = async () => {
      try {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${accessToken}`);
        myHeaders.append("X-CSRF-Token", tokenCSRF);
        myHeaders.append("Content-Type", "application/hal+json");
        const raw = JSON.stringify({
          _links: {
            type: {
              href: URL_WEB_APP + "/rest/type/user/user",
            },
          },
          user_picture: [
            {
              target_id: fid,
              description: "User picture",
            },
          ],
        });
        const requestOptions = {
          method: "PATCH",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };
        const response = await fetch(
          `${URL_WEB_APP}/user/${uid}?_format=hal_json`,
          requestOptions
        );
        const result = await response.text();
        return JSON.parse(result);
      } catch (ex) {
        console.log("parsing failed", ex);
      }
    };

    switch (provider) {
      case "vk": {
        userId = user.id;
        await getTokenCSRF().then((token) => {
          tokenCSRF = token;
        });
        userFullName = user.first_name + " " + user.last_name;
        ImgfileName = slugify(user.first_name) + "_" + slugify(user.last_name);
        await oauthWebApp(userFullName, user.id).then((result) => {
          if (result.error) {
            regUser().then((result) => {
              uid = result.uid[0].value;
              oauthWebApp(userFullName, user.id).then(async (result) => {
                accessToken = result.access_token;
                await getImageFile(user.photo_max_orig);
                if (imageFile) {
                  await uploadFile(imageFile, ImgfileName.toLowerCase()).then(
                    (result) => {
                      fid = result.fid[0].value;
                    }
                  );
                  await pathUser();
                }
              });
            });
          } else {
            accessToken = result.access_token;
          }
        });
        break;
      }
      case "ok": {
        userId = user.uid;
        await getTokenCSRF().then((token) => {
          tokenCSRF = token;
        });
        userFullName = user.first_name + " " + user.last_name;
        ImgfileName = slugify(user.first_name) + "_" + slugify(user.last_name);
        await oauthWebApp(userFullName, user.uid).then((result) => {
          if (result.error) {
            regUser().then((result) => {
              uid = result.uid[0].value;
              oauthWebApp(userFullName, user.uid).then(async (result) => {
                accessToken = result.access_token;
                await getImageFile(user.pic_3);
                if (imageFile) {
                  await uploadFile(imageFile, ImgfileName.toLowerCase()).then(
                    (result) => {
                      fid = result.fid[0].value;
                    }
                  );
                  await pathUser();
                }
              });
            });
          } else {
            accessToken = result.access_token;
          }
        });

        break;
      }
      default:
        break;
    }
  };

  const modalTitle = "Присоединяйтесь";
  const modalBody = (
    <>
      <Login
        authorizationUrl={`https://connect.ok.ru/oauth/authorize?client_id=${CLIENT_ID_OK}&layout=a&scope=VALUABLE_ACCESS&response_type=token&redirect_uri=${REDIRECT_URI}`}
        responseType="token"
        clientId={CLIENT_ID_OK}
        redirectUri={REDIRECT_URI}
        onSuccess={onSuccessOK}
        onFailure={onFailureOK}
        render={(renderProps) => (
          <div
            onClick={() => {
              renderProps.onClick();
              setProgress(true);
            }}
            className="soc-ok"
          >
            <Ok />
            <span className="title-icon">
              Авторизоваться через Одноклассники
            </span>
          </div>
        )}
      />

      <Login
        authorizationUrl={`https://oauth.vk.com/authorize?client_id=${CLIENT_ID_VK}&display=page&response_type=code&v=5.131&redirect_uri=${REDIRECT_URI}`}
        responseType="token"
        clientId={CLIENT_ID_VK}
        redirectUri={REDIRECT_URI}
        onSuccess={onSuccessVK}
        onFailure={onFailureVK}
        render={(renderProps) => (
          <div
            onClick={() => {
              renderProps.onClick();
              setProgress(true);
            }}
            className="soc-vk"
          >
            <Vk />
            <span className="title-icon">Авторизоваться через Вконтакте</span>
          </div>
        )}
      />
    </>
  );

  return (
    <div className="header">
      <Container className="header-top">
        <Menu />
        <div className="logo">
          <Link to="/">
            <Logo className="logo-header" />
          </Link>
          <div>ОДНОДУМ.RU</div>
        </div>
        <div className="wrap">
          <Logout user={context.user} />
          <SearchIcon className="icon-search" onClick={search} />

          {context.userAuthenticated === false && (
            <PersonOutlineIcon
              className="icon-user"
              onClick={() => {
                setStateModal(true);
              }}
            />
          )}
          {context.userAuthenticated === true && (
            <ExitToAppIcon
              className="icon-exit"
              onClick={() => {
                document.querySelector(".logout").classList.toggle("active");
              }}
            />
          )}
          <Search />
        </div>
      </Container>
      <ModalCustom
        isOpened={stateModal}
        onModalClose={() => {
          setStateModal(false);
        }}
        title={modalTitle}
        body={modalBody}
        progress={progress}
      />
    </div>
  );
};

export default Header;
