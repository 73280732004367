import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import parse from "html-react-parser";
import { SRLWrapper } from "simple-react-lightbox";

import "./AboutUsPage.scss";

const AboutUsPage = () => {
  const URL_WEB_APP = "https://cms-headless.odnodum.ru";
  const [body, setBody] = useState();

  const getData = async () => {
    try {
      const response = await fetch(
        `${URL_WEB_APP}//rest/api/about_us?_format=json`
      );
      const json = await response.json();
      setBody(parse(json[0].body));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div className="about-us-page animate__animated animate__fadeIn">
      <Container>
        <SRLWrapper>
          <div className="wrap">{body}</div>
        </SRLWrapper>
      </Container>
    </div>
  );
};

export default AboutUsPage;
