import TopBlockParallax from "./top-block-parallax/TopBlockParallax";
import MainBlock from "./main-block/MainBlock";
import "./HomePage.scss";

const HomePage = () => {
  return (
    <div className="home-page">
      <TopBlockParallax />
      <MainBlock />
    </div>
  );
};

export default HomePage;
